import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { checkBackendStatus, updateBackend } from "../../actions/backendVersionActions";
import { useCompatibilityMatrix } from "../../context/compatibilityMatrixContext";
import { useTranslation } from "../../context/TranslationContext";

import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";
import {
	SET_CERT_INVALID,
	SET_CERT_VALID,
	SET_BACKEND_UNAVAILABLE,
	SET_BACKEND_AVAILABLE,
	SET_BACKEND_VERSION,
	SET_UPDATING,
	SET_UPDATE_FINISHED,
	SET_UPDATE_REQUESTED,
	FETCH_COMPATIBLE_VERSIONS_SUCCESS,
	FETCH_COMPATIBLE_VERSIONS_FAILURE,
	SET_SAVED_TARGET_VERSION,
} from "../../context/backendStatus/backendStatusActions";
import useCognito from "../../hooks/useCognito";
import AuthContext from "../../context/authContext";
import { useAppContext } from "../../context/appContext";
import { StyledDialog, IconWrapper } from "../UpdateWarningModal/styles";
import ModalContent from "./ModalContent";
import ModalIcon from "./ModalIcon";

const UpdateModal = () => {
	const backendStatus = useBackendStatusContext();
	const { matrix } = useCompatibilityMatrix();
	const { signOut } = useCognito();
	// Access userData from AuthContext
	const { userData } = React.useContext(AuthContext);
	const { toggleCompatibility, isLocalHost, isAcquireRunning } = useAppContext();
	const savedCompatibility = localStorage.getItem("compatibility");
	const { translate } = useTranslation();
	const [hasDispatchedMatrix, setHasDispatchedMatrix] = useState(false);

	const handleSwitchChange = (event) => {
		const isChecked = event.target.checked;
		toggleCompatibility(isChecked);
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	};

	useEffect(() => {
		if (!matrix || !userData || !backendStatus.state.version || hasDispatchedMatrix) return;

		const isPreReleaseTester = userData.groups?.includes("Reyedar-pre-release");

		backendStatus.dispatch({
			type: FETCH_COMPATIBLE_VERSIONS_SUCCESS,
			payload: { data: matrix, isPreReleaseTester },
		});

		setHasDispatchedMatrix(true); // prevent re-dispatching
	}, [matrix, userData, backendStatus.state.version, hasDispatchedMatrix]);

	useEffect(() => {
		const updateStatus = async () => {
			// Skip if a test is running or if the user is part of a specific group
			if (
				isAcquireRunning === true ||
				(userData && userData.groups && userData.groups.includes("Reyedar-tester"))
			) {
				return;
			}

			try {
				const backendVersion = await checkBackendStatus().catch((err) => {
					if ((err.response.status === "401") | (err.response.status === "403")) {
						signOut();
					}
				});

				if (backendVersion.isCertValid) backendStatus.dispatch({ type: SET_CERT_VALID });
				else backendStatus.dispatch({ type: SET_CERT_INVALID });

				if (backendVersion.isAvailable) backendStatus.dispatch({ type: SET_BACKEND_AVAILABLE });
				else backendStatus.dispatch({ type: SET_BACKEND_UNAVAILABLE });

				if (backendVersion.version)
					backendStatus.dispatch({ type: SET_BACKEND_VERSION, payload: { version: backendVersion.version } });

				try {
					if (backendVersion.version === "Not able to read version.") {
						backendStatus.dispatch({ type: SET_UPDATING });
					} else if (backendStatus.state.isUpdating && backendVersion.version) {
						backendStatus.dispatch({
							type: SET_UPDATE_FINISHED,
							payload: {
								message: `Backend updated successfully to version: ${backendVersion.version}`,
								status: "Success",
							},
						});
					}
				} catch (error) {
					console.error("Error fetching compatible versions:", error);
					backendStatus.dispatch({
						type: FETCH_COMPATIBLE_VERSIONS_FAILURE,
						payload: error.message,
					});
				}
			} catch (err) {
				if ((err.response && err.response.status === 401) || err.response.status === 403) {
					signOut();
				}
			}
		};

		const interval = setInterval(
			() => {
				updateStatus();
			},
			backendStatus.state.isUpdating || !backendStatus.state.isAvailable ? 500 : 2000
		);

		return () => {
			clearInterval(interval);
		};
	}, [backendStatus, signOut]);

	const handleUpdate = () => {
		backendStatus.dispatch({ type: SET_UPDATE_REQUESTED });
		updateBackend();
		try {
			const backendVersion = checkBackendStatus();

			if (backendVersion.version) {
				backendStatus.dispatch({ type: SET_BACKEND_VERSION, payload: { version: backendVersion.version } });
			}

			backendStatus.dispatch({
				type: SET_UPDATE_FINISHED,
				payload: {
					message: `Backend updated successfully to version: ${backendVersion.version}`,
					status: "Success",
				},
			});

			sessionStorage.removeItem("updateWarningDismissed");
		} catch (err) {
			console.error("checkBackendStatus failed after update:", err);
		}
	};

	const isModalOpen =
		backendStatus.state.isUpdating ||
		backendStatus.state.isUpdateRequired ||
		backendStatus.state.isBackendCompatibleWithFrontend === false; // explicitly check for false

	return (
		<StyledDialog fullWidth open={isModalOpen} onClose={() => {}}>
			<IconWrapper>
				<ModalIcon
					isUpdating={backendStatus.state.isUpdating}
					isUpdateRequired={backendStatus.state.isUpdateRequired}
					isBackendCompatible={backendStatus.state.isBackendCompatibleWithFrontend}
				/>
			</IconWrapper>
			<Box p={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center", pt: 0 }}>
				<ModalContent
					backendStatus={backendStatus}
					translate={translate}
					handleUpdate={handleUpdate}
					isLocalHost={isLocalHost}
					savedCompatibility={savedCompatibility}
					handleSwitchChange={handleSwitchChange}
				/>
			</Box>
		</StyledDialog>
	);
};

export default UpdateModal;
