import React, { useEffect, useState } from "react";
import {
  DialogContentText,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { parse, format, isValid } from "date-fns";
import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";
import { StyledDialog, IconWrapper, StyledIconCircle, StyledTitle, StyledDialogActions } from "./styles";
import { useTranslation } from "../../context/TranslationContext";
import { useCompatibilityMatrix } from "../../context/compatibilityMatrixContext";

const UpdateWarningModal = () => {
    const [open, setOpen] = useState(false);
    const [deprecationDate, setDeprecationDate] = useState(null);
    const { matrix } = useCompatibilityMatrix();
    const { state } = useBackendStatusContext();
    const theme = useTheme();
    const { translate } = useTranslation();

    useEffect(() => {
        const dismissed = sessionStorage.getItem("updateWarningDismissed");

        if (state.isUpdateRequired || !matrix) return;
        
        const backendVersion = state.version;
        const backendVersions = matrix.backendVersions || [];

        const matchedVersion = backendVersions.find((v) => v.version === backendVersion);
        const date = matchedVersion?.deprecationDate;

        const isValidDate = date && date !== "DD-MM-YYYY";

        if (isValidDate && !dismissed) {
            setDeprecationDate(date);
            setOpen(true);
        }

    }, [matrix, state.version, state.isUpdateRequired]);

    const formattedDate = React.useMemo(() => {
        if (!deprecationDate) return "";

        const parsed = parse(deprecationDate, "dd-MM-yyyy HH:mm", new Date());

        if (!isValid(parsed)) {
            console.warn("Invalid date received from backend:", deprecationDate);
            return "";
        }

        return format(parsed, "MMMM do, HH:mm");
    }, [deprecationDate]); 

    const handleClose = () => {
        sessionStorage.setItem("updateWarningDismissed", "true");
        setOpen(false);
    };

    return (
        <StyledDialog
            fullWidth
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") handleClose();
            }}
            aria-labelledby="update-warning-title"
        >
            <IconWrapper>
                <StyledIconCircle>
                    <PriorityHighIcon sx={{ fontSize: "65px" }} />
                </StyledIconCircle>
            </IconWrapper>

            <StyledTitle variant="h5">
                {translate('updateWarningModal.title')}
            </StyledTitle>

            <DialogContentText id="update-warning-description" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body1" align="center" width="80%">
                    {translate("updateWarningModal.descriptionBefore")}{" "}
                    <strong style={{ color: theme.palette.text.primary }}>{formattedDate}</strong>{" "}
                    {translate("updateWarningModal.descriptionAfter")}
                </Typography>
                <Typography mb={2}>
                    {translate("updateWarningModal.installationNote")}
                    <span style={{ color: theme.palette.text.primary, fontWeight: "bold"}}> {translate("updateWarningModal.duration")}</span>
                </Typography>
            </DialogContentText>
            <StyledDialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{ minWidth: 100 }}
                    color="primary"
                >
                    OK
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default UpdateWarningModal;